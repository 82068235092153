'use strict';

import TextView from 'views/record/subfields/Text';
import ListCoupleSelection from 'views/record/subfields/subcomponents/ListCoupleSelection';
import * as _ from 'lodash';
import App from 'App';
import GetGridDataRequest from '../../../server/protocol/request/task/GetGridData';
import CClientConfiguration from 'parametrage/CClientConfiguration';
import { GridView } from '../../../parametrage/structures/GridView';
import Context from '../../../models/context/Context';
import Server from 'server/Server';
let $ = require('jquery');
import StringUtils from '../../../utils/StringUtils';
import User from 'User';
import DeviceUtils from '../../../utils/DeviceUtils';


class ListCoupleField extends TextView {

    private selectionPopup: ListCoupleSelection;
    private skipClick: boolean;
    private renderingRoot: JQuery<HTMLElement>;
    private innerRenderRoot: JQuery<HTMLElement>;
    private selection: Array<{value: string, label: string}>;
    private labelCache: {[value: string]: string};

    constructor (options){
        super(options);

        this.labelCache = {};
        this.selection = this.initSelection();
        this.selectionPopup = new ListCoupleSelection({
            referenceView: this,
            navigatorConfig: {
                template: _.template(''),
                taskId: this.fieldConfig.getTask().getId(),
                gridId: this.fieldConfig.getLinkedDataField().getGridView(),
                filterId: this.fieldConfig.getLinkedDataField().getFilterName()
            },
            fieldName: this.fieldConfig.getLabel(),
            domainContext: this.fieldConfig.getContext(),
            fieldConfig: this.fieldConfig,
            selection: this.selection
        });
        this.listenTo(this.selectionPopup, 'listCoupleSelection.onPrepareContext', this.onContextRequest.bind(this));
        this.listenTo(this.selectionPopup, 'onHide', this.onPopupHide.bind(this));
        this.listenTo(this.selectionPopup, 'change', this.onSelectionChange.bind(this));
    }

    onContextRequest(context: Context) {
        this.trigger('panel.onPrepareContext', context, true);
    }

    onDOMUpdated (initialContext? : Context) {
        super.onDOMUpdated();
        this.selectionPopup.setElement(this.$el.find('.selectionPopup'));
        this.selectionPopup.referenceInput = this.getFieldElement();

        this.bindClick();
        this.renderSelection(initialContext);
    }

    onSizeUpdated (newSize) {
        super.onSizeUpdated(newSize);
        this.renderSelection();
    }

    showSelection (){
        if(!this.selectionPopup.isShown && !this.skipClick){
            this.selectionPopup.setSelection(this.selection);
            var offset = this.$el.offset();
            this.selectionPopup.show();
            var fieldHeight = DeviceUtils.isMobile() ? 60 : 40;
            if(this.selectionPopup.$el.hasClass('bottomLocated')) {
                this.selectionPopup.$el.css('top', offset.top + fieldHeight);
            } else {
                this.selectionPopup.$el.css('top', offset.top - this.selectionPopup.$el.height());
            }
            if(DeviceUtils.getDisplayModeFromSize() === 'desktop') {
                var innerOffset = this.$el.find('.selectionRendering').offset();
                var windowWidth = $(window).width();
                var popupWidth = this.selectionPopup.$el.width();
                if(this.selectionPopup.$el.hasClass('leftPosition')) {
                    if(innerOffset.left + popupWidth >= windowWidth) {
                        this.selectionPopup.$el.css('right', 5);
                    } else {
                        this.selectionPopup.$el.css('left', innerOffset.left);
                    }
                } else {
                    var targetLeft = innerOffset.left + this.$el.find('.selectionRendering').width() -  popupWidth;
                    if(targetLeft + popupWidth >= windowWidth) {
                        this.selectionPopup.$el.css('right', 5);
                    } else {
                        this.selectionPopup.$el.css('left', targetLeft);
                    }
                }
            }
        }

        this.skipClick = false;
    }

    skipNextClick(){
        this.skipClick = true;
    }

    bindClick (){
        if(this.fieldState.isEnabled()){
            this.getFieldElement().on('click', this.showSelection.bind(this));
        }
    }

    bindOneMissingClick (){
        this.getFieldElement().on('click', this.fakeClick.bind(this));
    }

    unbindClick (){
        this.getFieldElement().off('click');
    }

    getFieldElement(){
        return this.$el.find('.selectionRendering, .listCoupleInput');
    }

    onPopupHide (wasInput){
        if(wasInput){
            this.unbindClick();
            this.bindOneMissingClick();
        }
        let promises = [];
        if(this.fieldState.getLastValue() !== this.fieldState.getValue()) {
            if(this.canBeChange()) {
                this.onFieldChange(promises);
            }
            this.$el.trigger('SAIFieldUpdate');
        }
        return Promise.all(promises);
    }

    fakeClick (){
        this.unbindClick();
        this.bindClick();
    }

    onSelectionChange (selection){
        if(selection.length === 0) {
            this.fieldState.setValue('');
        } else {
            this.fieldState.setValue(selection.map(function(item){
                return item.value;
            }).join(','));
        }
        this.selection = selection;
        for(let key in selection) {
            this.labelCache[selection[key].value] = selection[key].label;
        }
        this.renderSelection();
    }

    renderSelection (initialContext? : Context){
        this.renderingRoot = this.$el.find('.selectionRendering');
        this.innerRenderRoot = this.renderingRoot.children('.inner');
        this.innerRenderRoot.empty();

        let hasElements = false;
        let hasMissingLabels = false;
        for(var key in this.selection){
            hasElements = true;
            let toDisplay = this.selection[key].label;
            if(toDisplay === '') {
                toDisplay = this.labelCache[this.selection[key].value] || '';
            }
            if(toDisplay === '') {
                hasMissingLabels = true;
                toDisplay = this.selection[key].value;
            }
            $('<div class="renderedSelectionItem">'+toDisplay+'</div>').appendTo(this.innerRenderRoot);
        }

        if(hasElements && this.fieldState.isEnabled()){
            //insertion of clear icon
            let deleteIcon = $('<div class="renderedSelectionItem deleteItem">X</div>').appendTo(this.innerRenderRoot);
            deleteIcon.click(this.clearField.bind(this));
        }

        if(hasMissingLabels && this.fieldConfig.getDisplayColumnId() !== undefined) {
            let me = this;
            let labelRequest = new GetGridDataRequest(this.domainContext,
                this.fieldConfig.getTask().getId(),
                this.fieldConfig.getLinkedDataField().getGridView());
            if (initialContext) {
                if(initialContext.getContextId() === 'page'){
                    labelRequest.setPageContext(initialContext);
                } else {
                    labelRequest.setRecordContext(initialContext);
                }
            }
            labelRequest.setRange(0,-1);
            labelRequest.setIsMainGrid(false);
            CClientConfiguration.getGridView(this.fieldConfig.getLinkedDataField().getGridView(), this.domainContext)
                .then((gridView: GridView) => {
                    let columns = gridView.getColumns();
                    let pkCol;
                    for(let key in columns) {
                        if(columns[key].getDatafieldId().endsWith('_PK')) {
                            pkCol = columns[key];
                            break;
                        }
                    }
                    if(!pkCol) {
                        pkCol = columns[0];
                    }
                    let filterContext: Context = new Context({ contextId: 'filter' });
                    filterContext.addFields([{
                        datafieldId: pkCol.getDatafieldId(),
                        value: me.selection.map((entry) => entry.value).join(' OR ')
                    }]);
                    labelRequest.setFilterContext(filterContext);
                    Server.performRequest(labelRequest)
                        .then((result) => {
                            if(result.items) {
                                for(let rowId in result.items) {
                                    let curItem = result.items[rowId];
                                    let curItemKey = curItem.columns[pkCol.getId()].value;
                                    let curItemLabel = StringUtils.getMultilangTextValue(User.getLocale(), curItem.columns[me.fieldConfig.getDisplayColumnId()].value);
                                    let index = _.findIndex(me.selection, function (item) {
                                        return item.value === curItemKey;
                                    });
                                    if(index !== undefined && index >= 0) {
                                        me.selection[index].label = curItemLabel;
                                        me.labelCache[curItemKey] = curItemLabel;
                                    }
                                }
                                me.renderSelection();
                            }
                        })
                        .catch(App.displayErrorMessage)
                })
                .catch(App.displayErrorMessage);
        }
    }

    clearField(){
        this.skipNextClick();
        this.onSelectionChange([]);
        return this.performChange();
    }

    performChange(forced?: boolean) {
        if(forced === undefined) {
            forced = false;
        }
        let promises = [];
        if(this.fieldState.getLastValue() !== this.fieldState.getValue() || forced) {
            if(this.canBeChange()) {
                this.onFieldChange(promises, forced);
            }
            this.$el.trigger('SAIFieldUpdate');
        }
        let allProms = Promise.all(promises);
        allProms.then(() => {
            this.renderSelection();
        });
        return allProms;
    }

    setModelValue(value) {
        super.setModelValue(value);
        this.selection = this.initSelection();
        this.selectionPopup.setSelection(this.selection);
    }

    private initSelection(): Array<{value: string, label: string}> {
        if(this.fieldState.getValue() !== '') {
            let me = this;
            return this.fieldState.getValue().split(',').map((entry) => {
                let curredEntry = entry.trim();
                let label = me.labelCache[curredEntry];
                return { value: curredEntry, label: label === undefined ? '' : label };
            });
        } else {
            return [];
        }
    }
}

export default ListCoupleField;