'use strict';

import OutputTask from 'views/tasks/OutputTask';
import GridStatsView from 'views/tasks/grids/GridStats';
import Context from 'models/context/Context';
import DeviceUtils from 'utils/DeviceUtils';
import EventUtils from 'utils/EventUtils';
import ColorUtils from 'utils/ColorUtils';
import List from 'views/listing/List';
import GridRenderer from 'views/listing/renderer/grid/GridRenderer';

import GetGridDataRequest from 'server/protocol/request/task/GetGridData';
import SubmitRecordRequest from 'server/protocol/request/task/SubmitRecord';
import RecordPopupView from 'views/record/RecordPopup';
import TabBarView from 'views/tasks/TabBar';
import CClientConfiguration from 'parametrage/CClientConfiguration';

import App from 'App';
import User from 'User';
import Server from 'server/Server';
const moment = require('moment');
import { SAIEventCalendar, SAIEventManager, SAIEvent, SAITimePeriod, SAIEventInterfaceDisplayMode } from '@sai/historyviewer';
import MenuItemView from './MenuItem';
import NotificationManager from '../../utils/notification/NotificationManager';
import { PopupMenu, PopupMenuItem } from 'parametrage/structures/PopupMenu';
import { Header } from '../../models/listing/Header';
import LoadingMask from 'views/loading/LoadingMask';
import StringUtils from '../../utils/StringUtils';
import FloatingDialogView from '../modal/FloatingDialog';
import I18NUtils from 'utils/i18n/I18NUtils';
import TabbarItemView from './TabbarItem';

class ViewTask extends OutputTask {

    initialize (options) {
        super.initialize(options);
        this.ruptureStates = {};
        this.viewConfig = 'DEFAULT_CONFIG';
    }

    initInnerTaskProperties (){
        this.taskType = 'ViewTask';
        this.className = 'view-task';
    }

    render () {
        super.render();
        this.viewContainer = this.$el.find('.viewContainer');
        this.launcherView.$el.height(this.launcherView.getTotalHeight());
        if(!DeviceUtils.isFullScreenPageNav()) {
            this.launcherView.$el.width(this.launcherView.getMaxWidth());
        }

        this.getSearchIcon();

        let buttonDiv = this.$el.find('.launch-button');

        buttonDiv.on('click', this.getView.bind(this));

        this.recordContainer = this.$el.find('.recordContainer');
        this.listenTo(this.taskHeader, 'simplifyRequest', this.onSimplifyRequest.bind(this));
        this.renderTabBar();
    }

    getTabbarElement() {
        return this.$el.find('.tabbar');
    }

    getTabarTabs(){
        var tabs = [
            {
                id: 'home',
                name: 'Home',
                icon: 'home',
                position: 'center',
                enabled: true,
                type: 'icon'
            },{
                id: 'search',
                name: 'Search',
                icon: 'search',
                position: 'center',
                enabled: true,
                type: 'icon'
            }
        ];
        if(App.hasCallStack()) {
            tabs.push({
                id: 'back',
                name: 'Retour',
                icon: 'arrow-left',
                position: 'center',
                enabled: true,
                type: 'icon'
            });
        }

        tabs.push();
        return tabs;
    }

    onTabPressed(tab, args) {
        if (tab === 'home') {
            App.backToDesktop();
        } else if (tab === 'new') {
            this.onNewEventRequest(moment());
        } else if(tab === 'back') {
            App.popTask();
        } else if(tab === 'search') {
            this.getView();
        } else if(tab === 'popupmenu') {
            this.handlePopupmenuAction(args);
        }
    }

    renderTabBar() {
        if (DeviceUtils.isFullScreenPageNav()) {
            if (!this.caltabBar) {
                this.caltabBarEl = this.getTabbarElement();

                var tabs = this.getTabarTabs();

                this.caltabBar = new TabBarView({
                    el: this.caltabBarEl,
                    prefix: 'cal',
                    tabs: tabs,
                    domainContext: this.domainContext
                });
                this.caltabBar.render();
                this.listenTo(this.caltabBar, 'tabPressed', this.onTabPressed.bind(this));
            }
            this.caltabBarEl.show();
        } else {
            if (this.caltabBar) {
                this.caltabBarEl.hide();
            }
        }
    }

    setupInitialView() {
        //The goal here is to select the view selection combo and automatically
        //set its value to the first view if it's not already set

        let initialProps = this.getConfig().getInitialPropsOverride();
        let targetView = initialProps ? initialProps['gridId'] : undefined;

        let fields = this.launcherView.getFields();
        for (let fieldId in fields) {
            let curField = fields[fieldId].getConfig();
            if (curField.getDatafieldId() === 'DEFAULT_VIEW_TYPE') {
                let currentSelection = fields[fieldId].getState().getValue();
                if(!currentSelection) {
                    if(targetView) {
                        let fieldCombo = curField.getLinkedCombo();
                        let els = fieldCombo.getElements();
                        for(let i in els) {
                            let comboElement = els[i];
                            if(comboElement.getAttribute('gridView') === targetView) {
                                fields[fieldId].setModelValue(comboElement.getId());
                                this.launcherView.setField(fields[fieldId].getState(), true, true);
                            }
                        }
                    } else {
                        let fieldCombo = curField.getLinkedCombo();
                        let els = fieldCombo.getElements();
                        if(els && els.length > 0) {
                            fields[fieldId].setModelValue(els[0].getId());
                            this.launcherView.setField(fields[fieldId].getState(), true, true);
                        }
                    }
                }
            }
        }
        let groups = this.launcherView.getGroups();
        for(let i in groups) {
            groups[i].moveFieldsToPositions();
        }
        let launchAtStart = initialProps ? initialProps['launchAtStart'] : false;
        if(launchAtStart) {
            this.execute();
        }
    }

    onSimplifyRequest(mode) {
        if(mode === 'complexify') {
            this.recordContainer.show();
            this.$el.find('.launch-button').show();
        } else {
            this.recordContainer.hide();
            if(!DeviceUtils.isFullScreenPageNav()) {
                this.$el.find('.launch-button').hide();
            }
        }
        this.setTaskLayout();
    }

    setTaskLayout (){
        if(this.preventResize){
            return;
        }

        if(this.currentCalendar !== undefined) {
            //We need to re-render the calendar
            //Resize of the header and the view
            this.resizeViewContainer();

            //Resize of the calendar
            let calendarConfig = this.currentCalendar.getConfig();
            //The history should only rely on the config's dimensions which
            //is not the case at the moment
            calendarConfig.width = this.viewContainer.width() - 5;
            calendarConfig.height = this.viewContainer.height();
            this.currentCalendar.width = this.viewContainer.width() - 5;
            this.currentCalendar.height = this.viewContainer.height();
            //Reset of the interface but we keep the same date
            this.resetCalendar();
        }
    }

    getAvailableViewHeight() {
        let recordHeight = this.launcherView.$el.is(':visible') ? this.launcherView.$el.outerHeight(true) : 0;
        return this.$el.height() - recordHeight - this.taskHeader.$el.outerHeight(true) - 15;
    }

    onGridReceived (response) {
        //Simple grid
        this.viewContainer.empty();
        var items = this.latestItems = response.items;
        this.latestHeaders = response.headers;

        this.currentView =  new List({
            el: this.viewContainer,
            taskId: this.model.get('taskId'),
            gridId: this.currentViewProperties.attributes['gridId'],
            filterId: this.currentViewProperties.attributes['filterId'],
            data: response.items,
            headers: response.headers,
            formatter: response.formatter,
            renderer: new GridRenderer(),
            domainContext: this.domainContext
        });

        if(response.formatter && response.formatter.type === 'dynamicgrid'){
            this.newBtn.off('click');
            this.newBtn.on('click', this.handleAddClick.bind(this));
            this.newBtn.show();
        }

        this.currentView.taskId = this.model.get('taskId');
        this.currentView.inputTask = this.model.get('linkedTasks').input;

        this.currentView.render();
        //If the view requests an update we reload it
        this.listenTo(this.currentView, 'refreshRequest', this.loadCurrentView);
        this.listenTo(this.currentView, 'itemClicked', this.openEntry.bind(this));

        if (items.length > 0) {
            //We can make graph out of it
            //this.statsBtn.show(); //VBL TODO ?
        }
    }

    openEntry(selection){
        if(!this.popupLaunched){
            this.popupLaunched = true;
            var keys = selection.outputKeys();

            //The keys are using the formatter ids. We've to remap to initial grid column
            var formatter = this.currentView.getListing().getFormatter();
            if(formatter !== undefined){
                for (var arrayId in keys) {
                    keys[arrayId].key = formatter.get('headersMapping')[keys[arrayId].key].source.split(',')[0];
                }
            }

            var me = this;
            var mode = 'tablet';
            if(DeviceUtils.isSmallDevice()){
                mode = 'phone';
            }

            var newPopup = this.openEntryPopup = new RecordPopupView({
                mode: 'getRecord',
                operationTitle: 'Modification',
                recordModelKeys: keys,
                taskId: this.model.get('linkedTasks').input,
                handleDocuments: false,
                gridId: this.currentViewProperties.id,
                buttons: [
                    {
                        role: 'ok',
                        bootstrapClass: 'btn-success',
                        btnClass: 'recordFormAcceptBtn',
                        enableOnLoad: true,
                        disabled: true,
                        btnText: 'Validate',
                        operation: me.onPopupClicked.bind(me)
                    },
                    {
                        role: 'cancel',
                        bootstrapClass: 'btn-default',
                        btnClass: 'recordFormCancelBtn',
                        enableOnLoad: false,
                        disabled: false,
                        btnText: 'Cancel',
                        operation: me.onCancelCreateButtonClicked.bind(me)
                    }
                ],
                displayMode: mode,
                domainContext: this.context
            });
            newPopup.display(function(){
                me.popupLaunched = false;
            });
        }
    }

    onPopupClicked (evt) {
        var action = EventUtils.getAttributeValue(evt, 'role');
        if(action === 'ok'){
            //User validated the modification
            var values = this.openEntryPopup.recordView.getFieldsValues();
            var documents = [];
            if(this.openEntryPopup.docsPan){
                documents = this.openEntryPopup.docsPan.upFilesIds;
            }

            let submitRequest = new SubmitRecordRequest(this.domainContext, this.inputTask);

            let recContext = new Context();
            recContext.addFields(values);
            submitRequest.setRecordContext(recContext);

            submitRequest.setOperation('modify');
            submitRequest.setPendingDocuments(documents);

            Server.performRequest(submitRequest)
                .then(this.onModifySuccess.bind(this))
                .catch(App.displayErrorMessage);
        }
    }

    onModifySuccess(response){
        this.currentView.trigger('refreshRequest');
    }

    handleAddClick (evt) {
        evt.preventDefault();
        if(!this.popupLaunched){
            this.popupLaunched = true;
            var me = this;
            var mode = 'tablet';
            if(DeviceUtils.isSmallDevice()){
                mode = 'phone';
            }
            var newPopup = this.newRecordPopup = new RecordPopupView({
                mode: 'prepareNewEntry',
                operationTitle: 'New entry',
                recordModelKeys: [],
                handleDocuments: false,
                taskId: this.model.get('linkedTasks').input,
                buttons: [
                    {
                        role: 'add',
                        bootstrapClass: 'btn-success',
                        btnClass: 'recordFormAcceptBtn',
                        enableOnLoad: true,
                        disabled: true,
                        btnText: 'Ajouter',
                        operation: me.onCreateButtonClicked.bind(me)
                    },
                    {
                        role: 'cancel',
                        bootstrapClass: 'btn-default',
                        btnClass: 'recordFormCancelBtn',
                        enableOnLoad: false,
                        disabled: false,
                        btnText: 'annuler',
                        operation: me.onCancelCreateButtonClicked.bind(me)
                    }
                ],
                displayMode: mode,
                domainContext: this.context
            });
            newPopup.display(function(){
                me.popupLaunched = false;
            });
        }
    }

    onCreateButtonClicked () {
        //User validated the création
        var values = this.newRecordPopup.recordView.getFieldsValues();
        let submitRequest = new SubmitRecordRequest(this.domainContext, this.model.get('linkedTasks').input);

        let recContext = new Context();
        recContext.addFields(values);
        submitRequest.setRecordContext(recContext);

        submitRequest.setOperation('new');
        Server.performRequest(submitRequest)
            .then(this.loadCurrentView.bind(this))
            .catch(App.displayErrorMessage);
    }

    onCancelCreateButtonClicked () {
    }

    onStatsRequested (evt) {
        if (this.statsOpened) {
            this.closeStats();
            this.statsOpened = false;
        } else {
            this.statsOpened = true;

            if (!this.statsView) {
                this.statsHolder = $('<div class="statsHolder"></div>').appendTo(this.viewContainer);
                this.statsView = new GridStatsView({
                    el: this.statsHolder,
                    headers: this.latestHeaders,
                    rawData: this.currentView.rows,
                    graphConfigs: this.currentViewProperties.config
                });
                this.statsView.render();
            }

            this.currentView.$el.find('.table-responsive').addClass('col-md-6');
            this.statsHolder.show();
            this.statsView.onDOMUpdated();
        }
    }

    closeStats () {
        this.currentView.$el.find('.table-responsive').removeClass('col-md-6');
        this.statsHolder.hide();
    }

    onDOMUpdated() {
        super.onDOMUpdated();
        this.setupInitialView();
    }

    loadCurrentView () {
        super.loadCurrentView();

        var value = this.selectBox.val();
        if (value !== null) { //User selected a view

            //If we change view, there is no reason to keep the rupture states
            //As there are no chance that the rupture have the same IDs.
            if(this.lastSelectedView !== value){
                this.ruptureStates = {};
            }
            this.lastSelectedView = value;
            this.setLoadingViewMode();
            var current = this.currentViewProperties = this.gridMapping[value];
            this.statsView = undefined;
            this.statsOpened = false;
            if(this.statsBtn){
                this.statsBtn.hide();
            }
            if(this.newBtn){
                this.newBtn.hide();
            }

            var recordContext = new Context({ contextId: 'record'});
            recordContext.addPanelFields(this.paramsRecord.getFieldsValues(),this.paramsRecord.panelId);

            let dataRequest = new GetGridDataRequest(this.domainContext,this.model.get('taskId'), current.id);
            dataRequest.setFilterId(current.filterId);
            dataRequest.setFormatterId(current.formatterId);
            dataRequest.setRange(0,10000);
            dataRequest.setRecordContext(recordContext);

            Server.performRequest(dataRequest)
                .then(this.onGridReceived.bind(this))
                .catch(App.displayErrorMessage);
        }
    }

    handlePopupmenuAction(popupMenuItem) {
        let action = popupMenuItem.getNotification ? popupMenuItem.getNotification() : popupMenuItem.notification;
        if(action) {
            // Dirty hack for sma
            if(action.notificationId === 'GTP_GET_LAST_WEEK') {
                this.viewConfig = 'VALIDATION_CONFIG';
            } else if(action.notificationId === 'GTP_CI_WEEK_RESET') {
                this.viewConfig = 'DEFAULT_CONFIG';
            } else if(action.notificationId === 'GTP_SWITCH_CONFIG') {
                if(this.viewConfig === 'DEFAULT_CONFIG') {
                    this.viewConfig = 'VALIDATION_CONFIG';
                } else {
                    this.viewConfig = 'DEFAULT_CONFIG';
                }
            }

            let notificationManager = new NotificationManager({
                currentTaskContext: this,
                domainContext: this.domainContext,
                contextFiller: this.fillSideActionContext.bind(this, popupMenuItem),
            })
            if(action.type === 'remote') {
                

                let remoteArgs = {};
                for(let key in action.remoteArguments) {
                    remoteArgs[key] = StringUtils.resolveTextProperties(action.remoteArguments[key], notificationManager.context);
                }

                CClientConfiguration.getRemoteNotification(action.notificationId, action.remoteTask, remoteArgs)
                    .then((notif) => {
                        notificationManager.handle(notif);
                    })
                    .catch(App.displayErrorMessage);
            } else {
                notificationManager.handle(action);
            }
        }
    }

    handleTaskHeaderAction (actionType, popupMenuItem){
        if(actionType === 'close'){
            window.App.popTask();
        } else if(actionType === 'popupmenu') {
            this.handlePopupmenuAction(popupMenuItem);
        } else if(actionType === 'delete') {
            let viewProp = this.getSelectedViewProperties();
            if(viewProp.getAttribute('historyActionType') === 'Notification') {
                //Call the notification
                let notificationArguments = viewProp.getAttribute('remoteArguments');
    
                let notificationManager = new NotificationManager({
                    currentTaskContext: this,
                    domainContext: this.domainContext,
                    contextFiller: this.fillDeleteContext.bind(this, null),
                });
    
                let remoteArgs = this.buildRemoteArguments(notificationArguments, notificationManager.context);
                let keyContext = new Context({contextId: 'key'});
                keyContext.addFields([{
                    datafieldId: 'GTPEVT.NORECO_PK',
                    value: notificationManager.context['GTPEVT.NORECO_PK']
                }])
                CClientConfiguration.getRemoteNotification(viewProp.getAttribute('notificationID'), viewProp.getAttribute('remoteTask'), remoteArgs,keyContext)
                    .then((notif) => {
                        notificationManager.handle(notif);
                    })
                    .catch(App.displayErrorMessage);
            }
        } else if(actionType == 'copy') {
            this.copiedEvent = this.selectedEvent;
            this.desktopActionsMenu.setItemEnable('paste', true);
        } else if(actionType == 'paste') {
            this.onEventPaste(this.lastDateClick === undefined ? moment() : this.lastDateClick);
        }
    }

    fillSideActionContext(popupMenuItem, context) {
        context['itemId'] = popupMenuItem.itemId;
        context['popupId'] = popupMenuItem.popupId;
        Object.assign(context, this.getNotificationContextFromRecord());
        if(this.currentView){
            Object.assign(context, this.getNotificationContextFromSelectedRows(this.currentView.selectedItems));
        }
        let incomingValues = this.getNotificationContextFromSelectedEntry(this.fetchedHeaders, this.selectedEvent);
        for(let key in incomingValues) {
            context[key] = incomingValues[key];
        }
    }

    resizeViewContainer() {
        let availableHeight = this.getAvailableViewHeight();
        this.viewContainer.show();
        let presentation = this.currentViewProperties.getAttribute('presentation');
        let css = {
            width: this.$el.find('.taskBody').width() - 10
        }
        if(presentation === 'History') {
            css['position'] = 'absolute';
            css['top'] = this.launcherView.$el.is(':visible') ? (this.launcherView.$el.outerHeight(true)+5)+'px' : 0;
            css['padding-top'] = 0;
            css['height'] = availableHeight;
        }
        this.viewContainer.css(css);
    }

    execute() {
        let me = this;
        this.getView()
            .then(() => {
                me.trigger('SAIExecuteTaskResult','success');
            })
            .catch((error) => {
                console.error(error);
                me.trigger('SAIExecuteTaskResult','error');
            });
    }

    getView() {
        let me = this;
        return new Promise((acceptView, rejectView) => {
            var recordContext = new Context({ contextId: 'record'});
            recordContext.addPanelFields(this.launcherView.getFieldsValues(), this.launcherView.panelId);
            let viewProps = this.getSelectedViewProperties();
            if(!viewProps) {
                let message = I18NUtils.getMessage('VIEW_NO_SELECTION', User.getLocale(), {});
                App.displayErrorMessage(message);
                return;
            }
            this.currentViewProperties = viewProps;

            let presentation = viewProps.getAttribute('presentation');
            if(presentation === 'History') {
                //Initialisation and display of calendar

                //We perform a single request based on the selection parameters
                let dataRequest = new GetGridDataRequest(this.domainContext, this.taskConfig.getId(),viewProps.getAttribute('gridView'));
                dataRequest.setFilterId(viewProps.getAttribute('filter'));
                dataRequest.setFormatterId(viewProps.getAttribute('gridAdvancedFormatter'));
                dataRequest.setRecordContext(recordContext);
                dataRequest.setRange(0, -1);
                dataRequest.setFilterContext(this.getFilterContext());

                this.resizeViewContainer();

                if(!this.currentCalendar) {
                    this.floatingDialog = $('<div></div>');
                    this.$el.find('.taskBody').append(this.floatingDialog);
                    this.calendarLegend = new FloatingDialogView({
                        el: this.floatingDialog,
                        title: 'Légende', //TODO i18n
                        canClose: false,
                        canMinimize: true,
                        position: {x: this.viewContainer.width()-200, y: this.viewContainer.offset().top + 50}
                    });
                    this.calendarLegend.render();
                    this.currentCalendar = new SAIEventCalendar({
                        rootElement: this.viewContainer,
                        width: this.viewContainer.width()-5,
                        height: this.viewContainer.height(),
                        debug: true,
                        locale: User.getLocale(),
                        manager: new SAIEventManager({
                            fetchEvent: function (period, callback) {
                                me.calendarCallBack = callback;
                                me.refreshCurrentViewEvents(period, callback);
                            },
                            lazyRetrieval: false
                        }),
                        displayMode: SAIEventInterfaceDisplayMode.WEEKS,
                        availableModes: [SAIEventInterfaceDisplayMode.WEEKS, SAIEventInterfaceDisplayMode.MONTHES],
                        listeners: {
                            'newEvent' : this.onNewEvent.bind(this),
                            'monthChanged': this.onMonthChanged.bind(this),
                            'yearChanged': this.onYearChanged.bind(this),
                            'daySelected': this.onDaySelected.bind(this),
                            'eventSelected': this.onEventSelected.bind(this),
                            'weekChanged': this.onWeekChanged.bind(this),
                            'eventUnselected': this.onEventUnselect.bind(this),
                            'openEvent' : this.onOpenEvent.bind(this),
                            'eventMenu' : this.onOpenEventMenu.bind(this),
                            'backgroundMenu' : this.onOpenBackgroundMenu.bind(this),
                            'eventCopy' : this.onEventCopy.bind(this),
                            'eventPaste' : this.onEventPaste.bind(this)
                        },
                        legendDialog: this.calendarLegend,
                        instanceId: this.getTaskInstanceId()
                    });
                } else {
                    //Reset of the interface but we keep the same date
                    this.resetCalendar(true);
                }

                let loadingRequestId;
                this.historyConfig = {};
                // hardcoded for sma
                if(this.viewConfig === 'DEFAULT_CONFIG' && this.taskConfig.getId() === 'CRMPC') {
                    this.historyConfig = {
                        start: 'START',
                        end: 'END',
                        title: ['STATESTR'],
                        colorKey: 'LINKTYPE',
                        colorLegend: 'LINKTYPE_NA',
                        description: ['UNITS', 'BENEFICIARIES','LOCATION','AN_DELAI']
                    };
                } else if(this.viewConfig === 'DEFAULT_CONFIG' && this.taskConfig.getId() === 'CRMPB') {
                    this.historyConfig = {
                        start: 'START',
                        end: 'END',
                        title: ['STATESTR'],
                        colorKey: 'LINKTYPE',
                        colorLegend: 'LINKTYPE_NA',
                        description: ['UNITS', 'EMPLOYEES','LOCATION','AN_DELAI']
                    };
                } else if(this.viewConfig === 'VALIDATION_CONFIG' && this.taskConfig.getId() === 'CRMPC') {
                    this.historyConfig = {
                        start: 'START',
                        end: 'END',
                        title: ['STATESTR'],
                        colorKey: 'VALIDATION_STATUS',
                        colorLegend: 'VALIDATION_STATUS',
                        description: ['UNITS', 'BENEFICIARIES','LOCATION','AN_DELAI']
                    };
                } else if(viewProps.getAttribute('gridView') === 'CRMEV_PLANNING' && this.taskConfig.getId() === 'CRMEV') {
                    this.historyConfig = {
                        start: 'START',
                        end: 'END',
                        title: ['LINKEDTASK_NA', 'LABEL'],
                        colorKey: 'NATURE',
                        colorLegend: 'NATURE',
                        description: ['LINKEDTASK_NA', 'LABEL', 'LINKEDPRJNA', 'PRIORITY']
                    };
                }
                LoadingMask.requestLoading('Chargement des événements')
                    .then((reqId) => {
                        // Get the data and the popup menus
                        loadingRequestId = reqId;
                        return Promise.all([Server.performRequest(dataRequest),CClientConfiguration.getPopupMenu('grid',viewProps.getAttribute('gridView'),this.domainContext)]);
                    })
                    .then(([result, gridPop]) => {
                        // Get the datafield used for the legend
                        this.fetchedHeaders = new Header(result.headers);
                        let promisesList = [result, gridPop];
                        let colorLegendDatafield = this.getDatafieldForTableColumn(this.historyConfig.colorLegend);
                        if(colorLegendDatafield) {
                            promisesList.push(CClientConfiguration.getDataField(colorLegendDatafield,this.domainContext,this.taskConfig));
                        }

                        for(let i in this.historyConfig.description) {
                            let descriptionElement = this.historyConfig.description[i];
                            let elemDataField = this.getDatafieldForTableColumn(descriptionElement);
                            if(elemDataField) {
                                promisesList.push(CClientConfiguration.getDataField(elemDataField,this.domainContext,this.taskConfig));
                            }
                        }
                        return Promise.all(promisesList);
                    })
                    .then((resultArray) => {
                        let result = resultArray[0];
                        let gridPop = resultArray[1];
                        let legendDatafield = resultArray[2];
                        // Get the enum linked to the legend datafield
                        let promisesList = [result, gridPop];
                        
                        let startIndex = 2;
                        while(startIndex < resultArray.length) {
                            // handle the description datafields
                            let descriptionDf = resultArray[startIndex];
                            if(descriptionDf && descriptionDf.getEnumName()) {
                                promisesList.push(new Promise((accept, reject) => {
                                    CClientConfiguration.getCombo(descriptionDf.getEnumName(),User.getLocale(),this.domainContext,this.taskConfig.getTableID())
                                        .then((combo) => {
                                            accept({
                                                datafieldId: descriptionDf.getId(),
                                                combo: combo
                                            });
                                        }).catch(reject);
                                }));
                            }
                            startIndex++;
                        }

                        return Promise.all(promisesList);
                    })
                    .then((resultArray) => {
                        let result = resultArray[0];
                        let gridPop = resultArray[1];

                        let comboMap = {};
                        for(let i = 2; i < resultArray.length; i++) {
                            let elem = resultArray[i];
                            comboMap[elem['datafieldId']] = elem['combo'];
                        }

                        //injection of all data into the calendar
                        if(this.calendarCallBack) {
                            let start = moment().startOf('week');
                            let end = moment().endOf('week');
                            let period = new SAITimePeriod(start, end);
                            this.fetchedEvents = [];
                            for (var index in result.items) {
                                var item = result.items[index];
                                var newEvent = me.buildNewEvent(item,comboMap);
                                //We don't want sticky events as we want to permanently refresh all events when changing period
                                this.fetchedEvents.push(newEvent);
                            }
                            this.calendarCallBack(period, this.fetchedEvents);
                        }
                        this.handleCalendarGridPopupMenu(gridPop.getActions([PopupMenu.SCOPE_ALWAYS, PopupMenu.SCOPE_SINGLE]));
                        acceptView();
                    })
                    .catch((message) => {
                        App.displayErrorMessage(message)
                        rejectView(message);
                    })
                    .then(() => {
                        LoadingMask.hide(loadingRequestId);
                    })
            } else {
                //Standard view. We fetch the whole data set at once
                this.viewContainer.empty();
                this.currentView =  new List({
                    el: this.viewContainer,
                    taskId: this.taskConfig.getId(),
                    gridId: viewProps.getAttribute('gridView'),
                    formatterId: viewProps.getAttribute('gridAdvancedFormater'),
                    filterId: viewProps.getAttribute('filter'),
                    renderer: new GridRenderer(),
                    domainContext: this.domainContext,
                    start: 0,
                    end: -1,
                    multiSelect: {
                        selectedItemsClass: 'selectedItem',
                        selectedKeys: '',
                        keyColumn: ''
                    }
                });
                this.listenTo(this.currentView, 'list.onPrepareContext', this.onContextRequest.bind(this));
                this.listenTo(this.currentView, 'list.onSelectionChanged', this.onSelectionChanged.bind(this));
                //The data will be handled by a standard listing component
                this.currentView.getListing().fetchNextPage('true');
                this.currentView.render();
                this.resizeViewContainer();
                if(this.calendarLegend) {
                    this.calendarLegend.hide();
                }
                CClientConfiguration.getPopupMenu('grid',viewProps.getAttribute('gridView'),this.domainContext)
                    .then((popup) => {
                        this.handleGridPopupMenu(popup.getActions([PopupMenu.SCOPE_ALWAYS, PopupMenu.SCOPE_SINGLE]));
                        acceptView();
                    })
                    .catch(rejectView);
            }
        });
    }

    onSelectionChanged(items) {
        var nbSelected = items.length;
        var allRowsContext = [];
        for(var item in items) {
            allRowsContext.push(this.buildRowContext(items[item]));
        }
        if(nbSelected === 0) {
            this.desktopActionsMenu.hideActionsWithScope(PopupMenu.SCOPE_SINGLE);
            this.desktopActionsMenu.hideActionsWithScope(PopupMenu.SCOPE_MULTIPLE);
        } else if(nbSelected === 1) {
            this.desktopActionsMenu.displayActionsWithScope(PopupMenu.SCOPE_SINGLE, allRowsContext[0]);
            this.desktopActionsMenu.hideActionsWithScope(PopupMenu.SCOPE_MULTIPLE);
        } else {
            this.desktopActionsMenu.toggleActionsVisibilityFromMultiContext(PopupMenu.SCOPE_MULTIPLE, allRowsContext, true);
            this.desktopActionsMenu.hideActionsWithScope(PopupMenu.SCOPE_SINGLE);
        }
    }

    buildRowContext(row) {
        var context = {};
        var values = row.outputAllDataFieldsValues();
        for(var index in values) {
            var value = values[index];
            context['$'+value.datafieldId] = value.value;
        }
        return context;
    }

    onOpenEventMenu(saiEvent, d3Event) {
        this.displayCurrentMenuPopup(d3Event);
    }

    onOpenBackgroundMenu(dateClick, d3Event) {
        this.displayCurrentMenuPopup(d3Event);
        this.lastDateClick = dateClick;
    }

    displayCurrentMenuPopup(d3Event) {
        let menuItems = this.desktopActionsMenu.getDisplayedItems();
        let finalItemsList = {};
        for(let key in menuItems) {
            let item = menuItems[key];
            if(item.getType() === 'close' || !item.enabled) {
                continue;
            }
            let popupMenuItem = item.getItem();
            finalItemsList[key] = {
                name: item.getLabel()
            }
            if(popupMenuItem && popupMenuItem.hasSubItems()) {
                finalItemsList[key].items = {};
                let subs = popupMenuItem.getSubItems();
                for(let entry in subs) {
                    finalItemsList[key].items[entry] = {
                        name: subs[entry].getLabel(),
                        callback: () => {
                            item.trigger('action', item, item.getType(), subs[entry]);
                        }
                    }
                }
            } else {
                finalItemsList[key].callback = () => {
                    item.trigger('action', item, item.getType(), popupMenuItem);
                }
            }
        }
        $.contextMenu('destroy', '#' + this.getTaskInstanceId() + ' .calendar');
        $.contextMenu({
            selector: '#' + this.getTaskInstanceId() + ' .calendar',
            appendTo: '#' + this.getTaskInstanceId() + ' .task-panel',
            items: finalItemsList
        });
        $('#' + this.getTaskInstanceId() + ' .calendar').contextMenu({x: d3Event.pageX, y: d3Event.pageY});
    }

    canSimplifyInterface() {
        return true;
    }

    resetCalendar(dropEvents) {
        if(dropEvents) {
            this.fetchedEvents = [];
        }
        if(this.currentPeriod) {
            //Only the config should be changed instead of duplicating
            //the start and end in both the interface and the config
            this.currentCalendar.setStart(this.currentPeriod.getStart());
            this.currentCalendar.setEnd(this.currentPeriod.getEnd());
            let config = this.currentCalendar.getConfig();
            config.start = this.currentPeriod.getStart();
            config.end = this.currentPeriod.getEnd();
        } else {
            this.currentPeriod = new SAITimePeriod(this.currentCalendar.getStart(), this.currentCalendar.getEnd());
        }
        this.currentCalendar.resetInterface(this.currentPeriod ? this.currentPeriod.getStart(): undefined);
        this.calendarCallBack(this.currentPeriod, this.fetchedEvents);
        this.onEventUnselect();
    }

    getSelectedViewProperties() {
        let fields = this.launcherView.getFields();
        for (let fieldId in fields) {
            let curField = fields[fieldId].getConfig();
            let currentSelection = fields[fieldId].getState().getValue();
            if (curField.getDatafieldId() === 'DEFAULT_VIEW_TYPE') {
                let fieldCombo = curField.getLinkedCombo();
                if(currentSelection !== undefined) {
                    return fieldCombo.getElement(currentSelection);
                }
            }
        }
        return undefined;
    }

    getFilterContext() {
        let fields = this.launcherView.getFields();
        for (let fieldId in fields) {
            let curField = fields[fieldId].getConfig();
            if (curField.getDatafieldId() === 'DEFAULT_VIEW_FILTER') {
                return fields[fieldId].getFilterContext();
            }
        }
        return undefined;
    }

    setModelValue(field) {
        this.launcherView.setField(field, true, true);
    }

    getSearchIcon() {
        let container = this.$el.find('.launch-button');
        let me = this;
        let imgSrc = Server.getTokenedUrl('configuration/' + this.domainContext.getId() + '/image/highres,big/128,100,64,36/sai-search-button');
        let prom = new Promise((resolve, reject) => {
            var xhr = new XMLHttpRequest();
            xhr.open('GET', imgSrc);
            xhr.onload = () => {
                resolve(xhr.response);
            };
            xhr.send();
        })
            .then((response) => me.handleGetImg([response,imgSrc])
            ).then((element) => {
                container.append(element);
                container.append('<div class="label icon">Afficher les données</div>');
                element.css('margin-left', (container.find('.label').width() - element.width())/2 + 'px');
            }).catch((error) => {
                container.append('<button type="button">Afficher les données</button>');
                console.error(error);
            }).finally(() => {
                container.css('margin-top',(me.launcherView.$el.height() - container.height())/2);
                if(!DeviceUtils.isFullScreenPageNav()) {
                    container.css('left', this.launcherView.$el.width() + 20);
                }
            });
    }

    handleGetImg([response, imgSrc]) {
        var me = this;
        return new Promise((resolve, reject) => {
            let jsonResult = undefined;
            try {
                jsonResult = JSON.parse(response);
            } catch(e) {
                // Nothing to do
            }
            if (jsonResult !== undefined && jsonResult.status === 404) {
                reject(new Error(JSON.parse(response).message.body));
            } else  if (response.indexOf('<svg') >= 0) {
                var randNum = Math.floor(Math.random() * 100000);
                response = response.replace(new RegExp('icon-shadow','g'),'icon-shadow-'+randNum);
                response = response.replace(new RegExp('background-clip','g'),'background-clip-'+randNum);
                var xmlSvg = $($.parseXML(response));
                var size = '50px';
                xmlSvg.find('svg').attr('width', size).attr('height',size);
                resolve(xmlSvg.find('svg'));
            } else {
                let imgElement = $('<img src="'+imgSrc+'"/>');
                resolve(imgElement);
            }
        });
    }

    onNewEvent(startDate, endDate ) {
        let viewProp = this.getSelectedViewProperties();
        if(viewProp.getAttribute('historyActionType') === 'Notification') {
            //Call the notification
            let notificationArguments = viewProp.getAttribute('remoteArguments');

            let notificationManager = new NotificationManager({
                currentTaskContext: this,
                domainContext: this.domainContext,
                contextFiller: this.fillContext.bind(this, startDate, endDate),
            })

            let remoteArgs = this.buildRemoteArguments(notificationArguments, notificationManager.context);

            CClientConfiguration.getRemoteNotification(viewProp.getAttribute('notificationID'), viewProp.getAttribute('remoteTask'), remoteArgs)
                .then((notif) => {
                    notificationManager.handle(notif);
                })
                .catch(App.displayErrorMessage);
        }
    }

    onEventCopy() {
        this.copiedEvent = this.selectedEvent;
        this.desktopActionsMenu.setItemEnable('paste', true);
    }

    onEventPaste(startDate, endDate) {
        let viewProp = this.getSelectedViewProperties();
        if(viewProp.getAttribute('historyActionType') === 'Notification') {
            //Call the notification
            let notificationArguments = viewProp.getAttribute('remoteArguments');

            let notificationManager = new NotificationManager({
                currentTaskContext: this,
                domainContext: this.domainContext,
                contextFiller: this.fillPasteContext.bind(this, startDate),
            })

            let remoteArgs = this.buildRemoteArguments(notificationArguments, notificationManager.context);
            let keyContext = new Context({contextId: 'key'});
            keyContext.addFields([{
                datafieldId: 'GTPEVT.NORECO_PK',
                value: notificationManager.context['GTPEVT.NORECO_PK']
            }])
            CClientConfiguration.getRemoteNotification(viewProp.getAttribute('notificationID'), viewProp.getAttribute('remoteTask'), remoteArgs, keyContext)
                .then((notif) => {
                    notificationManager.handle(notif);
                })
                .catch(App.displayErrorMessage);
        }
    }

    fillContext(startDate, endDate, context) {
        context['operation'] = 'new';
        let startOp = startDate ? startDate : moment();
        context['start'] = startOp.format('YYYY-MM-DD HH:mm:00');
        let endOp = endDate ? endDate : moment(startDate).add(1, 'hours');
        context['end'] = endOp.format('YYYY-MM-DD HH:mm:00');
        Object.assign(context, this.getNotificationContextFromRecord());
    }

    fillDeleteContext(item, context) {
        context['operation'] = 'delete';
        Object.assign(context, this.getNotificationContextFromRecord());
        Object.assign(context, this.getNotificationContextFromSelectedEntry(this.fetchedHeaders, this.selectedEvent));
    }

    fillPasteContext(start, context) {
        context['operation'] = 'paste';
        context['start'] = start.format('YYYY-MM-DD HH:mm:00');
        Object.assign(context, this.getNotificationContextFromRecord());
        Object.assign(context, this.getNotificationContextFromSelectedEntry(this.fetchedHeaders, this.copiedEvent));
    }

    fillOpenContext(item, context) {
        context['operation'] = 'modify';
        Object.assign(context, this.getNotificationContextFromRecord());
        Object.assign(context, this.getNotificationContextFromSelectedEntry(this.fetchedHeaders, this.selectedEvent));
    }

    getNotificationContextFromRecord() {
        let fields = this.launcherView.getFields();
        let allEntries = {};
        for(let id in fields) {
            let curField = fields[id];
            if(curField.hasInnerValues()) {
                let innerVals = curField.getInnerValues();
                for(let key in innerVals) {
                    allEntries[key] = innerVals[key];
                }
            } else {
                let state = fields[id].getState();
                let value = state.getValue();
                let datafieldId = state.getDatafieldId();
                allEntries[datafieldId] = value;
                let linkedDf = state.getLinkedDataField();
                if(linkedDf && linkedDf.getId() !== datafieldId) {
                    allEntries[linkedDf.getId()] = value;
                }
            }
        }
        return allEntries;
    }

    buildRemoteArguments(notificationArguments, context) {
        let result = {};
        let re = /([a-zA-Z0-9]+)=(\${[a-zA-Z0-9\.]+})/g
        let match;
        while((match = re.exec(notificationArguments)) !== undefined && match !== null) {
            let replaceValue = CClientConfiguration.resolveTextProperties(match[2], context);
            let remoteArgKeyValue;
            if(replaceValue !== '') {
                remoteArgKeyValue = CClientConfiguration.resolveTextProperties(match[2], context);
            } else {
                remoteArgKeyValue='';
            }
            result[match[1]] = remoteArgKeyValue;
        }
        return result;
    }

    onMonthChanged(month, year) {
        console.debug('month changed '+ month);
    }

    onYearChanged(year) {
        console.debug('year changed ' + year);
    }

    onDaySelected(date, month, year) {
        console.debug('day selected' + date + ' ' + month + ' ' + year);
    }

    onEventSelected(saievent) {
        let eventContext = this.getScriptContextFromSelectedEntry(this.fetchedHeaders, saievent);
        this.selectedEvent = saievent;
        this.desktopActionsMenu.displayActionsWithScope(PopupMenu.SCOPE_SINGLE, eventContext);
    }

    onEventUnselect() {
        this.selectedEvent = undefined;
        this.desktopActionsMenu.hideActionsWithScope(PopupMenu.SCOPE_SINGLE);
    }

    onWeekChanged(date) {
        let start = moment(date).startOf('week');
        let end = moment(date).endOf('week');
        let period = new SAITimePeriod(start, end);
        this.calendarCallBack(period, this.fetchedEvents);
        this.currentPeriod = period;
    }

    onOpenEvent(saievent) {
        let viewProp = this.getSelectedViewProperties();
        let notificationArguments = viewProp.getAttribute('remoteArguments');
        let notificationManager = new NotificationManager({
            currentTaskContext: this,
            domainContext: this.domainContext,
            contextFiller: this.fillOpenContext.bind(this, null),
        })

        let remoteArgs = this.buildRemoteArguments(notificationArguments, notificationManager.context);
        let keyContext = new Context({contextId: 'key'});
        keyContext.addFields([{
            datafieldId: 'GTPEVT.NORECO_PK',
            value: notificationManager.context['GTPEVT.NORECO_PK']
        }]);
        CClientConfiguration.getRemoteNotification(viewProp.getAttribute('notificationID'), viewProp.getAttribute('remoteTask'), remoteArgs,keyContext)
            .then((notif) => {
                notificationManager.handle(notif);
            })
            .catch(App.displayErrorMessage);
    }

    refreshCurrentViewEvents(period, callback) {
        if(this.fetchedEvents !== undefined && this.fetchedEvents.length > 0) {
            callback(period,this.fetchedEvents);
        }
    }

    handleCalendarGridPopupMenu(actions) {
        this.desktopActionsMenu.removeActionsWithScope('grid');
        // Add the remove event
        let removeEventIconPath = Server.getTokenedUrl('configuration/' + this.domainContext.getId() + '/image/highres,big/128,100,64,36/sai-trash');
        let removeEventItem = new MenuItemView({
            item: new PopupMenuItem({
                scope: 'SingleRecord'
            }),
            label: 'Supprimer l\'évènement',
            iconPath: removeEventIconPath,
            actionType: 'delete',
            actionScope: 'grid',
            id: 'delete'
        })
        this.desktopActionsMenu.addMenuItem(removeEventItem);

        // Add the copy event
        let copyEventIconPath = Server.getTokenedUrl('configuration/' + this.domainContext.getId() + '/image/highres,big/128,100,64,36/sai-copy');
        let copyEventItem = new MenuItemView({
            item: new PopupMenuItem({
                scope: 'SingleRecord'
            }),
            label: 'Copier l\'évènement',
            iconPath: copyEventIconPath,
            actionType: 'copy',
            actionScope: 'grid',
            id: 'copy'
        })
        this.desktopActionsMenu.addMenuItem(copyEventItem);

        // Add the paste event
        let pasteEventIconPath = Server.getTokenedUrl('configuration/' + this.domainContext.getId() + '/image/highres,big/128,100,64,36/sai-paste');
        let pasteEventItem = new MenuItemView({
            item: new PopupMenuItem({
                scope: 'AllVisibleRecords'
            }),
            label: 'Coller l\'évènement',
            iconPath: pasteEventIconPath,
            actionType: 'paste',
            actionScope: 'grid',
            enabled: false,
            id: 'paste'
        })
        this.desktopActionsMenu.addMenuItem(pasteEventItem);

        for(let i in actions) {
            let item = actions[i];
            let iconPath = Server.getTokenedUrl('configuration/' + this.domainContext.getId() + '/image/highres,big/128,100,64,36/'+item.getIcon());
            let menuItem = new MenuItemView({
                item: item,
                iconPath: iconPath,
                actionType: 'popupmenu',
                actionScope : 'grid'
            });
            this.desktopActionsMenu.addMenuItem(menuItem);
        }

        this.desktopActionsMenu.hideActionsWithScope(PopupMenu.SCOPE_SINGLE);
    }

    handleGridPopupMenu(actions) {
        if (DeviceUtils.isFullScreenPageNav()) {
            this.caltabBar.deleteActionsWithScope('grid');
        } else {
            this.desktopActionsMenu.deleteActionsWithScope('grid');
        }
        for(let i in actions) {
            let item = actions[i];
            let iconPath = Server.getTokenedUrl('configuration/' + this.domainContext.getId() + '/image/highres,big/128,100,64,36/'+item.getIcon());
            
            if (DeviceUtils.isFullScreenPageNav()) {
                let tabbarItem = new TabbarItemView({
                    item: item,
                    iconPath: iconPath,
                    actionType: 'popupmenu',
                    actionScope : 'grid',
                });
                this.caltabBar.addItem(tabbarItem);
            } else {
                let menuItem = new MenuItemView({
                    item: item,
                    iconPath: iconPath,
                    actionType: 'popupmenu',
                    actionScope : 'grid'
                });
                this.desktopActionsMenu.addMenuItem(menuItem);
            }
        }
        if (DeviceUtils.isFullScreenPageNav()) {
            this.caltabBar.hideActionsWithScope(PopupMenu.SCOPE_SINGLE);
        } else {
            this.desktopActionsMenu.hideActionsWithScope(PopupMenu.SCOPE_SINGLE);
        }
    }

    /**
     * Builds events based on the calendar configuration
     * @param {*} item The raw content of the grid row
     */
    buildNewEvent(item, comboMap) {
        //The config is hardcoded for now
        let fakeEventId = Math.floor(Math.random()*100000000);//item.columns['ID'].value + '-' + item.columns['LINKTYPE'].value;
        let newEvent = new SAIEvent(fakeEventId,
            moment(item.columns[this.historyConfig.start].value),
            moment(item.columns[this.historyConfig.end].value),
            item);
        newEvent.setTitle(this.buildTitle(item));
        let colorLegend = this.getFieldHumanValue(item, this.historyConfig.colorLegend, comboMap);
        newEvent.setColorLegend(colorLegend);
        newEvent.setColor(this.getColorFromConfigPalette(item.columns[this.historyConfig.colorKey].value, colorLegend));

        newEvent.setPopupTitle(this.buildPopupTitle(item, comboMap));
        return newEvent;
    }

    buildTitle(item) {
        let result = '';
        for(let i in this.historyConfig.title) {
            let value = item.columns[this.historyConfig.title[i]].value;
            if(value) {
                if(result.length > 0) {
                    result += ', ';
                }
                result += value;
            }
        }
        if(result.length === 0) {
            result = 'Sans titre';
        }
        return result;
    }

    /**
     * Generate the popupTitle according to the header and item values
     * @param {*} item 
     */
    buildPopupTitle(item, comboMap) {
        let result = '';
        let map = this.fetchedHeaders.getColumnsMap();
        for(let i in this.historyConfig.description) {
            let descrItem = this.historyConfig.description[i];
            let value = this.getFieldHumanValue(item, descrItem, comboMap);
            if(value) {
                if(result.length > 0) {
                    result += '\n';
                }
                result += map[descrItem].getLabel() + ' : ' + value
            }
        }
        return result;
    }

    getColorFromConfigPalette(value, label) {
        //This function returns the color as set in the configuration. If the
        //color is not set, the it returns the random color that will be always
        //mapped based on the hash

        //[HARDCODED PALETTE]
        let palette = {};

        if(this.viewConfig === 'DEFAULT_CONFIG') {
            palette = {
                'A.CCI.ANNULATION': '#3f1c66',
                'A.CFCI.ANNULATION': '#452375',
                'A.CI.ANNULATION': '#351759',
                'A.CCI.SUPPRESSION': '#ff0000',
                'A.CFCI.SUPPRESSION': '#ff0000',
                'P.CCI.THEORIQUE': '#008cd3',
                'P.CCI.PROPOSITION': '#ffcc00',
                'P.CI.REALISED': '#ff8c00',
                'P.CI.THEORIQUE': '#008c00',
                'P.TRIP': '#e6e6e6',
                'P.FROMHOME.TRIP': '#dadada',
                'P.TOHOME.TRIP': '#cdcdcd'
            }
        } else if(this.viewConfig === 'VALIDATION_CONFIG') {
            palette = {
                '01': '#e7e7e7',
                '02': '#b4b4b4',
                '03': '#008cd3',
                '04': '#008c46',
                '05': '#ff8c00',
                '06': '#351759',
                '07': '#401c66',
                '08': '#472475',
                '09': '#ffcc00'
            }
        }

        let colorValue = palette[value];
        if(colorValue !== undefined) {
            return colorValue;
        } else if(label === undefined) {
            return ColorUtils.getRandomColor(value);
        } else {
            return ColorUtils.intToRGB(label);
        }
        //return palette[value] !== undefined? palette[value] : ColorUtils.getRandomColor(value);
    }

    /**
     * Transforms an SAIEvent object into a context map for script evaluation
     * based on the definition of the current grid columns
     * @param {*} headers The Header object that holds the columns properties
     * @param {*} saievent The SAIEvent to parse
     */
    getScriptContextFromSelectedEntry(headers, saievent) {
        let finalContext = {};
        let colsMap = headers.getColumnsMap();
        let eventData = saievent.data.columns;
        for(let colKey in eventData) {
            let entry = eventData[colKey];
            let mappedHeader = colsMap[entry.name];
            let datafieldId = '$' + mappedHeader.getDatafieldId();
            if(datafieldId.indexOf('DOSSIER.') === 7) {
                datafieldId = datafieldId.replace('DOSSIER.', '.');
            }
            let dotIndex = datafieldId.indexOf('.');
            let curContext = finalContext;
            //In javascript, it's not allowed to have dotted variables. Thus
            // $GTPEVT.SOMETHING = '3'
            //must be transpiled into
            // $GTPEVT = { SOMETHING : '3' }
            if(dotIndex > 0 ) {
                while(dotIndex > 0) {
                    let firstPart = datafieldId.substring(0, dotIndex);
                    let secondPart = datafieldId.substring(dotIndex + 1, datafieldId.length);
                    if(curContext[firstPart] === undefined) { curContext[firstPart] = {} ;}
                    curContext = curContext[firstPart];
                    datafieldId = secondPart;
                    dotIndex = datafieldId.indexOf('.');
                }
            }
            curContext[datafieldId] = entry.value;
        }
        return finalContext;
    }

    getNotificationContextFromSelectedEntry(headers, saievent) {
        let finalContext = {};
        if(!saievent) { return finalContext; }
        let colsMap = headers.getColumnsMap();
        let eventData = saievent.data.columns;
        for(let colKey in eventData) {
            let entry = eventData[colKey];
            let mappedHeader = colsMap[entry.name];
            let datafieldId = mappedHeader.getDatafieldId();
            if(datafieldId.indexOf('DOSSIER.') === 6) {
                datafieldId = datafieldId.replace('DOSSIER.', '.');
            }
            finalContext[datafieldId] = entry.value;
        }
        return finalContext;
    }

    getNotificationContextFromSelectedRows(rows) {
        let rowContext = {};
        if(rows.length === 0) { return rowContext; }
        if(Array.isArray(rows) && rows.length > 1) {
            for(var i in rows) {
                var r = rows[i];
                var rValues = r.outputAllDataFieldsValues();
                for(var rIndex in rValues) {
                    var rValue = rValues[rIndex];
                    var key = rValue.datafieldId;
                    if(!rowContext[key]) {
                        rowContext[key] = [];
                    }
                    rowContext[key].push(rValue.value);
                }
            }
        } else {
            var allRowDfValues = Array.isArray(rows) ? rows[0].outputAllDataFieldsValues() : rows.outputAllDataFieldsValues();
            for(var index in allRowDfValues) {
                var dfValue = allRowDfValues[index];
                var dfKey = dfValue.datafieldId;
                rowContext[dfKey] = dfValue.value;
            }
        }
        return rowContext;
    }

    getDatafieldForTableColumn(column) {
        let mappedColumn = this.fetchedHeaders.getColumnsMap()[column];
        if(mappedColumn) {
            return mappedColumn.getDatafieldId();
        }
        return undefined;
    }

    getFieldHumanValue(item, columnId, comboMap) {
        let column = item.columns[columnId];
        if(column) {
            let value = column.value;

            let datafield = this.getDatafieldForTableColumn(columnId);
            let enumElem = comboMap && comboMap[datafield] ? comboMap[datafield].getElement(value) : undefined;
            if (enumElem) {
                value = enumElem.getLabel();
            }
            return value;
        } else {
            console.warn('Column ' + columnId + ' does not exists in received data');
            return '';
        }
    }
}

export { ViewTask };
