'use strict';

import { SAIView } from '../../Additions';
import App from 'App';
import { PopupMenuItem } from 'parametrage/structures/PopupMenu';
let $ = require('jquery');
let contextMenu = require('jquery-contextmenu');

export default class TabbarItemView extends SAIView {
    private textOverlay: string;

    private item: PopupMenuItem;
    private actionScope: string;
    private actionType: string;
    private actionArguments: any;
    private iconPath: string;
    private overrideLabel: string;
    private menuCounter: number;
    private static counter: number = 0;
    private enabled: boolean;
    public id: string;
    private svgIcon:JQuery<HTMLElement>;

    constructor(options) {
        super(options);
        this.options = options;

        this.iconPath = options.iconPath
        this.actionType = options.actionType;
        this.actionScope = options.actionScope;
        this.actionArguments = options.actionArguments;
        this.item = options.item;
        this.overrideLabel = options.label;
        this.template = require('ejs-loader!templates/TabbarItem.ejs');
        this.menuCounter = TabbarItemView.counter++;
        this.enabled = options.enabled !== undefined ? options.enabled : true;
        this.id = options.id;
    }

    render() : any {
        this.$el.html(this.template({
            label: this.overrideLabel ? this.overrideLabel : (this.item ? this.item.getLabel() : 'non défini'),
            action: this.actionType,
            subItems: this.item? this.item.getSubItems(): [],
            menuCounter: this.menuCounter,
            enabled : this.enabled
        }));
        let me = this;
        App.getSvgIcon(this.iconPath,{h:30,w:30})
        .then((element:JQuery<HTMLElement>) => {
            let currentIcon = me.$el.find('.menu-item-icon').children();
            if(!me.svgIcon || currentIcon.length === 0) {
                me.svgIcon = element;
                me.$el.find('.menu-item-icon').append(element);
            }
        });
        
        if (this.textOverlay !== undefined) {
            this.$el.find('.menu-item-icon').append('<div class="svg-overlay"><span class="svg-overlay-text">'+this.textOverlay+'</span><svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 20 20" class="svg-text-overlay"><circle style="opacity:1;fill:#fff;fill-opacity:1;stroke:#fb6e52;stroke-width:2px;stroke-linecap:square;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1;paint-order:markers fill stroke" id="path1387" cx="10" cy="10" r="9" /></svg><div>')
        }
        if(this.actionScope) {
            this.$el.addClass(this.actionScope);
        }

        this.$el.addClass('tab-button');
        this.$el.addClass('tab-button-icon');
    }

    public onDomUpdated() {
        let me = this;
        if(this.item && this.item.getSubItems().length > 0) {
            let items = this.buildSubMenuItems();
            $.contextMenu({
                selector: '.menu-item[data-menucounter=' + this.menuCounter+']',
                items: items,
                callback: function(event, options) {
                    me.triggerAction(event, me.item.getSubItems()[options]);
                }
            });
            this.$el.on('click', function(event) {
                event.preventDefault();
                ($('.menu-item[data-menucounter=' + me.menuCounter+']') as any).contextMenu({
                    x: event.pageX,
                    y: event.pageY
                });
            })
        } else {
            this.$el.on('click', this.triggerAction.bind(this));
        }

        this.$el.prop('disabled',!this.enabled);
    }

    private buildSubMenuItems(): { [itemId:string]: {[prop:string] : string}} {
        let items = {};
        for(let key in this.item.getSubItems()) {
            let subItem = this.item.getSubItems()[key];
            items[key] = { name: subItem.getLabel() };
        }
        return items;
    }

    triggerAction(evt, subItem) {
        let caller = undefined;
        if(subItem) {
            caller = subItem.element ? subItem.element : subItem;
        } else if(this.item) {
            caller = this.item;
        } else if(this.actionArguments && this.actionArguments.notificationId !== undefined) {
            caller = this.actionArguments;
        }
        this.trigger('action', this, this.actionType, caller);
    }

    addTextOverlay(text: string) {
        this.textOverlay = text;
    }

    public getScope(): string {
        return this.item? this.item.getScope(): undefined;
    }

    public getVisible(): string {
        return this.item.getVisible();
    }

    public getActionScope(): string {
        return this.actionScope;
    }

    public getItem(): PopupMenuItem {
        return this.item;
    }

    public getType(): string {
        return this.actionType;
    }

    public getLabel(): string {
        return this.item && this.item.getLabel() ? this.item.getLabel() : this.overrideLabel;
    }

    public setEnable(enabled: boolean) {
        this.enabled = enabled;
        this.render();
    }

}