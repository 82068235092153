/**
 * An SAITabbar is a customizable tabs bar that doesn't show active tab
 * and that fire generic events based on what is given as tabs.
 *
 * The idea with this component is to be able to add tabs bars
 * pretty much everywhere and to let the controler of the
 * element that is adding the bar handle the result through generic events.
 *
 * The major difference with standard tab panels is that the tab bar doesn't
 * contain the panels that it shows on click. The tap events are catched and
 * new events are fired to higher components in order to be able to do anything we want.
 * This allows to perform operations without changing tabs. The best examples
 * are refreshing/next/previous tabs that does not require the component to
 * change.
 *
 * As buttons are quite hugly in bars, the use of the beautiful tabs was required.
 *
 * Here is an example of bar creation  :
 *
 * var tabbar = Ext.create("SAIMobile.view.SAITabBar", {
 *   eventLauncher: this,
 *   eventViewManager: manager,
 *   prefix: 'ENav',
 *   items: myItems
 * });
 */
'use strict';

import Backbone from 'backbone';

import TabBarMenu from 'views/tasks/TabBarMenu';
import DeviceUtils from 'utils/DeviceUtils';
import ScriptUtils from 'utils/ScriptUtils';
import { SAIView } from '../../Additions';

class SAITabBarView extends SAIView {

    initialize(options) {
        /*
         * Tab format is
         *
         * {
         *   id: unique id that links to this tab
         *   name: human friendly name that is displayed
         *   icon: name of the icon to use
         * }
         */
        this.tabs = [];
        this.events = {};
        this.template = require('ejs-loader!templates/SAITabBar.ejs');
        this.templateBtn = require('ejs-loader!templates/SAITabBarBtn.ejs');

        this.tabs = options.tabs || [];
        this.prefix = options.prefix || '';
        this.dockLocation = options.dockLocation || 'bottom';
        this.domainContext = options.domainContext;
        this.popupActions = [];
    }

    render() {

        var size = DeviceUtils.isSmallDevice() ? 'small' : 'medium';
        var hasHidden = false;
        var hasGroup = false;
        var position = {
            left: [],
            right: [],
            center: []
        };
        this.groupedTabs = [];

        for (var key in this.tabs) {
            var currentTab = this.tabs[key];
            if (currentTab[size + 'Grouped']) {
                hasGroup = true;
                this.groupedTabs.push(currentTab);
            }
            if (currentTab.position && position[currentTab.position] !== undefined) {
                position[currentTab.position].push(currentTab);
            } else {
                position.center.push(currentTab);
            }

            if (!currentTab.type) {
                currentTab.type = '';
            }
        }

        if (hasGroup) {
            position.right.push({
                id: 'system_group',
                position: 'right',
                type: 'icon',
                name: '',
                icon: 'ellipsis-v',
                enabled: true
            });
        }

        this.$el.html(this.template({
            leftTabs: position.left,
            centerTabs: position.center,
            rightTabs: position.right,
            btnTpl: this.templateBtn,
            hasGroup: hasGroup,
            size: size
        }));

        this.$el.addClass('sai-nav-bar');
        this.$el.addClass('docked-at-' + this.dockLocation);

        this.$el.find('.tab-button').click(this.onTabBarClick.bind(this));
    }

    onTabBarClick(e) {
        var current = $(e.currentTarget);
        var id = current.data('id');
        var parent = current.parent();
        this.startTabBarAnimation(id, parent, {X:e.pageX, Y:e.pageY});
    }

    startTabBarAnimation(id, parent, position) {
        //create .ink element if it doesn't exist
        if (parent.find('.ink').length === 0) {
            parent.prepend('<span class="ink"></span>');
        }

        var ink = parent.find('.ink');
        //incase of quick double clicks stop the previous animation
        ink.removeClass('animate');

        //set size of .ink
        if (!ink.height() && !ink.width()) {
            //use parent's width or height whichever is larger for the diameter to make a circle which can cover the entire element.
            var d = Math.max(parent.outerWidth(), parent.outerHeight());
            ink.css({ height: d, width: d });
        }

        //get click coordinates
        //logic = click coordinates relative to page - parent's position relative to page - half of self height/width to make it controllable from the center;
        var x = position.X - parent.offset().left - ink.width() / 2;
        var y = position.Y - parent.offset().top - ink.height() / 2;

        //set the position and add class .animate
        ink.css({ top: y + 'px', left: x + 'px' }).addClass('animate');

        if (id === 'system_group') {
            //special case, it means that we clicked on the group of actions
            //that have been defined as hidden. Thus we don't trigger the
            //tab pressed now. The tab pressed will however be trigger on the
            //menu selection
            this.newMenu = new TabBarMenu({
                template: '<div class="groupMenu col-md-12" data-index="${itemId}"><div class="icon"><i class="fa fa-${values.icon}"></i></div><div clas="text">${values.name}</div></div>',
                entries: this.groupedTabs
            });
            this.listenTo(this.newMenu, 'entrySelected', this.onSubMenuSelected.bind(this));
            this.newMenu.display();
        } else if(id !== 'animation') {
            this.trigger('tabPressed', id);
        }
    }

    onSubMenuSelected(id) {
        this.stopListening(this.newMenu);
        this.trigger('tabPressed', id);
    }

    updateTabText(id, text) {
        var tab = this.$el.find('div[data-id="' + id + '"]');
        if (tab.length !== 0) {
            tab.find('.tab-text').text(text);
        } else {
            console.warn('no such tab id ' + id);
        }
    }

    setTabVisibility(id, visible) {
        var tab = this.$el.find('div[data-id="' + id + '"]');
        if (tab.length !== 0) {
            if(visible) {
                tab.show();
            } else {
                tab.hide();
            }
        } else {
            console.warn('no such tab id ' + id);
        }
    }

    setTabModified() {
        var id = 'save';
        var tab = this.$el.find('div[data-id="' + id + '"]');
        if (tab.length !== 0) {
            if(!tab.hasClass('highlighted')) {
                tab.addClass('highlighted');
                this.startTabBarAnimation('animation',tab.parent(),{
                    X: tab.offset().left + (tab.outerWidth() / 2),
                    Y: tab.offset().top + (tab.outerHeight() / 2)
                })
            }
        } else {
            console.warn('no such tab id ' + id);
        }
    }

    setTabUnmodified() {
        var id = 'save';
        var tab = this.$el.find('div[data-id="' + id + '"]');
        if (tab.length !== 0) {
            tab.removeClass('highlighted');
        } else {
            console.warn('no such tab id ' + id);
        }
    }

    addIfAbsent(tabItem) {
        if(tabItem !== undefined) {
            var isPresent = false;
            for (var index in this.tabs) {
                if (this.tabs[index].id === tabItem.id) {
                    isPresent = true;
                    break;
                }
            }
            if( !isPresent) {
                this.tabs.push(tabItem);
            }
        }
    }

    removeItem(tabItemId) {
        for (var index = this.tabs.length - 1; index >= 0; index --) {
            if (this.tabs[index].id === tabItemId) {
                this.tabs.splice(index,1);
            }
        }
    }

    updateAccessRights(accessRights) {
        for (var index in this.tabs) {
            let tab = this.tabs[index];

            if(tab.id === 'save') {
                tab.enabled = accessRights.indexOf('M') >= 0;
            } else if(tab.id === 'delete') {
                tab.enabled = accessRights.indexOf('D') >= 0;
            }
        }
        this.render();
    }

    addItem(item, position) {
        item.render();
        if(!position) {
            position = 'center';
        }
        let targetGroup = '';
        switch(position) {
            case 'right':
                targetGroup = '.rightTabs';

                break;
            case 'left':
                targetGroup = '.leftTabs';
                break;
            case 'center':
                targetGroup = '.centerTabs';
                break;
            default:
                break;
        }
        this.$el.find(targetGroup).append(item.$el);
        item.onDomUpdated();
        this.listenTo(item, 'action', this.bubbleMenuAction.bind(this));
        this.popupActions.push(item);
    }

    bubbleMenuAction(item, actionType, actionArguments) {
        this.trigger('tabPressed', actionType, actionArguments);
    }

    deleteActionsWithScope(scope) {
        this.$el.find('.tab-button.'+scope).remove();
    }

    hideActionsWithScope(scope) {
        this.shouldDisplayActionWithScope(scope, {}, false);
    }

    shouldDisplayActionWithScope(scope, context, display) {
        for(let i in this.popupActions) {
            let item = this.popupActions[i];
            if(item.getScope() === scope) {
                if(!display) {
                    item.$el.hide();
                } else {
                    //Check of the visibility condition
                    let visible = true;
                    let visibilityCondition = item.getVisible();
                    if(visibilityCondition !== undefined) {
                        visible = ScriptUtils.evalInContext(visibilityCondition, context);
                    }
                    if(visible === true) {
                        item.$el.show();
                    } else {
                        item.$el.hide();
                    }
                }
            }
        }
    }
}

export default SAITabBarView;
